// extracted by mini-css-extract-plugin
export var bodyBase = "status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodyLarge = "status-label-module--body-large--d411d status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodyLargeBold = "status-label-module--body-large-bold--40d18 status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodyRegular = "status-label-module--body-regular--f0154 status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodyRegularBold = "status-label-module--body-regular-bold--67618 status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodySmall = "status-label-module--body-small--430b0 status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var bodySmallBold = "status-label-module--body-small-bold--9a79c status-label-module--body-base--fe036 status-label-module--site-font--02119";
export var borderTop = "status-label-module--border-top--93157";
export var box = "status-label-module--box--7ce42";
export var breakWordContainer = "status-label-module--break-word-container--08ccd";
export var buttonIconBase = "status-label-module--button-icon-base--23786";
export var clickLink = "status-label-module--click-link--78144";
export var dropdownBase = "status-label-module--dropdown-base--ba39e";
export var dropdownSelectBase = "status-label-module--dropdown-select-base--e9ed8 status-label-module--text-input--0e881";
export var error = "status-label-module--error--3007d";
export var flexCol = "status-label-module--flex-col--3ecd2";
export var formErrorMessage = "status-label-module--form-error-message--0e9d6";
export var h3 = "status-label-module--h3--3ff15";
export var h4 = "status-label-module--h4--67463";
export var hoverLink = "status-label-module--hover-link--ce293";
export var hoverRow = "status-label-module--hover-row--09246";
export var label = "status-label-module--label--aa5e0";
export var membershipContainer = "status-label-module--membership-container--6689d status-label-module--flex-col--3ecd2 status-label-module--primary-border--384cd";
export var membershipHeader = "status-label-module--membership-header--db496";
export var membershipHeading = "status-label-module--membership-heading--2bab1";
export var membershipLabel = "status-label-module--membership-label--7fdbf";
export var moreFiltersBorderClass = "status-label-module--more-filters-border-class--0aeb5";
export var neutral = "status-label-module--neutral--1311c";
export var neutral2 = "status-label-module--neutral2--2d251";
export var pageBg = "status-label-module--page-bg--411a2";
export var pointer = "status-label-module--pointer--81946";
export var primaryBorder = "status-label-module--primary-border--384cd";
export var rounded = "status-label-module--rounded--4d9a8";
export var shadowBoxLight = "status-label-module--shadow-box-light--027f1";
export var siteFont = "status-label-module--site-font--02119";
export var slideDownAndFade = "status-label-module--slideDownAndFade--485e1";
export var slideLeftAndFade = "status-label-module--slideLeftAndFade--478d5";
export var slideRightAndFade = "status-label-module--slideRightAndFade--1d891";
export var slideUpAndFade = "status-label-module--slideUpAndFade--bb0c7";
export var statusDecoration = "status-label-module--status-decoration--bf921";
export var success = "status-label-module--success--8390a";
export var textInput = "status-label-module--text-input--0e881";
export var textInverted = "status-label-module--text-inverted--b8a0e";
export var textMediumDark = "status-label-module--text-medium-dark--e6e78";
export var tooltipFont = "status-label-module--tooltipFont--765bf";
export var unstyledButton = "status-label-module--unstyled-button--a1e5a";
export var warning = "status-label-module--warning--fab7d";