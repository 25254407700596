import React from 'react';

import { Grid } from '@material-ui/core';
import { useParams } from '@reach/router';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import AdminTable from 'src/components/admin-table/admin-table';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import EmptyState from 'src/components/empty-state/empty-state';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { H2, H3, H4 } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';
import { capitalize } from 'src/utils/helper/rankings';
import ROUTES from 'src/utils/routes';

import { useSaaSMembership } from './saas-membership.api';
import * as styles from './saas-membership.module.less';

interface ItemProps {
  dt: string;
  dd?: string;
  leftMargin?: boolean;
}

function Item({ dt, dd, leftMargin = true }: ItemProps) {
  return (
    <dl
      className={cx(styles.itemContainer, {
        [styles.itemContainerLeftMargin]: leftMargin,
      })}
    >
      <dt className={styles.itemDt}>{dt}</dt>
      {dd && <dd className={styles.itemDd}>{dd}</dd>}
    </dl>
  );
}

export function SaaSMembership({ membershipId }: { membershipId: string | undefined }) {
  const { t } = useTranslation();
  const { membership, loadingMembership } = useSaaSMembership({
    id: membershipId,
  });

  if (loadingMembership) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumbs
        paths={[
          { name: t('membership list'), to: ROUTES.MEMBERSHIPS },
          { name: membership?.name || '', active: true },
        ]}
      />
      <PageMaxWidth>
        <Panel>
          <Grid container>
            <H2>{membership?.name || ''}</H2>
            <div className={styles.divider} />
          </Grid>
          <Grid container>
            <Item dt={t('code')} dd={membership?.code} leftMargin={false} />
            <Item dt={t('type')} dd={capitalize(membership?.type?.toLowerCase())} />
            {/* <Item dt={t('start date')} dd={''} /> */}
            {/* <Item dt={t('end date')} dd={''} /> */}
            {/* <Item dt={t('memberships sold')} dd={''} /> */}
            {/* <Item dt={t('revenue')} dd={''} /> */}
            {/* <Item dt={t('status')} dd={''} /> */}
          </Grid>
        </Panel>

        {/* <Panel> */}
        {/*   <> */}
        {/*     {false ? ( */}
        {/*       <EmptyState title="There are no members" icon="lg-empty-state" /> */}
        {/*     ) : ( */}
        {/*       <Grid container> */}
        {/*         <H3>{t('organizations')}</H3> */}
        {/*         <div className={styles.divider} /> */}
        {/*       </Grid> */}
        {/*     )} */}
        {/*   </> */}
        {/* </Panel> */}
      </PageMaxWidth>
    </>
  );
}
